import React from "react";
import Footer from "../Sections/Footer";
import Header from "../Sections/Header";

function Privacy() {
  return (
    <div>
      <Header />
      <h3>Le Cercle Français - Privacy Policy</h3>

      <div className="paragraph">
        <p>Effective Date: January 2, 2024</p>
        <p>
          1. Introduction 
          <br />
          <br />
          Welcome to Le Cercle Français, committed to delivering
          exceptional French language education. This Privacy Policy details our
          practices regarding the collection, use, protection, and disclosure of
          information we obtain from students and visitors, in compliance with
          the Information Technology Act, 2000, in India.
          <br />
          <br />
          2. Data Collection
          <br />
          <br />
           We collect personal information when you enroll in
          our courses, access online resources, or participate in institute
          activities. This includes: Name, contact details, and demographic
          information Educational background Payment and billing information
          Feedback and responses to surveys 
          <br />
          <br />
          3. Purpose of Data Collection 
          <br />
          <br />
          Your
          information is primarily used to: Facilitate course enrollment and
          provide educational materials Process payments and maintain financial
          records Enhance our curriculum and services based on feedback
          Communicate important information about our courses and events
          <br />
          <br />
          4. Data Sharing and Disclosure 
          <br />
          <br />In accordance with the Information

          Technology Act, we ensure your data is not sold or rented to third
          parties. It may be shared with: Academic partners for certification
          purposes Third-party service providers for payment processing and
          technical support Authorities, if mandated by law or for legal
          compliance 
          <br />
          <br />
          5. Data Security 
          <br />
          <br />
          We implement suitable security measures to
          safeguard your information against unauthorized access, alteration, or
          destruction, adhering to the standards stipulated in the Information
          Technology Act.
          <br />
          <br />
          6. Data Retention 
          <br />
          <br />Personal data is retained only as long as

          necessary for the purposes stated in this policy, or as required by
          Indian law.
          <br />
          <br />
          7. Your Rights 
          <br />
          <br />You have the right to: Access, correct, or update your

          personal information Request the deletion of your data in certain
          circumstances Withdraw consent for the use of your data, subject to
          legal or contractual restrictions
          <br />
          <br />
          8. Changes to This Policy 
          <br />
          <br />We reserve the right to modify this policy.

          Any significant changes will be communicated through our website or
          via email.
          <br />
          <br />
          9. Contact Us 
          <br />
          <br />For queries or concerns regarding this privacy policy,
          
          please contact us at contact@lecerclefrancais.in
        </p>
        <div className="h64"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
