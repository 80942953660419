import React from "react";
import { useCookieConsent } from "../CookieConsentContext"; // Adjust the import path as needed
import "./CookiesPopup.css";

function CookiesPopup() {
  const { showCookieConsent, handleAccept, handleRefuse, isLoading } = useCookieConsent();

  // The component will not render if 'isLoading' is true or 'showCookieConsent' is false
  if (!showCookieConsent || isLoading) return null;

  return (
    <div className="dive">
   
      <div className="cookie-popup">
        <h3>Cookies Consent 🍪</h3>
        <p>
          When you visit our site, cookies are placed on your device. We give
          you the opportunity to configure your consent.
          
          Visit our <a href="/cookies"><span className="underline__cookies">Cookie Policy</span></a>
          {"  "}for more information.
        </p>
        <div className="ContainerButton">
        <div className="cookie-button_refuse" onClick={handleRefuse}>
          <p>Refuse</p>
        </div>
        <div className="cookie-button_accept" onClick={handleAccept}>
          <p>Accept</p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default CookiesPopup;
