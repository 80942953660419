import React, { useState } from "react";
import "../Sections/Header.css";
import Burger from "../component/Burger";

function Header() {
  return (
    <div className="Header">
      <div className="Menu">
        <div className="Header__burger">
          <Burger />
        </div>
      </div>
    </div>
  );
}

export default Header;
