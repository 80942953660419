import React, { useEffect, useRef, useState } from "react";
import Background from "../../img/Background.png";
import LCFR from "../../img/LCFR.png";
import "./LandingPage.css";

function LandingPage() {
  const [typedText, setTypedText] = useState("");
  const originalTexts = [
    "Bonjour, comment ça va ??",
    "Bienvenue dans Le Cercle Français !"
  ];
  
  const typingSpeed = 200; // Vitesse de frappe en millisecondes
  const textIndexRef = useRef(0);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setTypedText(originalTexts[textIndexRef.current].slice(0, index));
      index++;

      if (index > originalTexts[textIndexRef.current].length) {
        index = 0; // Reset index to restart typing
        setTypedText(""); // Clear the typed text

        // Move to the next text
        textIndexRef.current =
          (textIndexRef.current + 1) % originalTexts.length;
      }
    }, typingSpeed);

    return () => {
      clearInterval(intervalId);
    };
  }, []); // Exécute cet effet une seule fois au montage du composant

  return (
    <div className="V1Landing">
      <a href="/">
      <div className="V1Landing__logo">
        <img src={LCFR} alt="Le Cercle Français Logo" />
      </div>
      </a>

      <div className="Heading">
        <p>{typedText}</p>
      </div>
      <div className="Heading__tagline">
        <p>
          Whether you're aspiring to study in France, explore career
          opportunities, or simply passionate about the language, Le Cercle
          Français is your first step towards achieving those dreams.
        </p>
      </div>

      <div className="V1Landing_Background">
        <img src={Background} alt="Le Cercle Français Background" />
      </div>
      <div className="V1Landing_Overlay"></div>
    </div>
  );
}

export default LandingPage;
