import React from "react";
import Adults from "../img/Adults.png";
import logoblack from "../img/logoblack.png";
import Footer from "./Footer";
import { ArrowForward } from "@mui/icons-material";
import "./KidPage.css";

function AdultPage() {
  return (
    <div className="V1Coursecontent">
      <div className="V1Coursecontent__container1">
        <a href="/">
          <div className="V1Coursecontent__container1__logo">
            <img src={logoblack} alt="" />
          </div>
        </a>
        <div className="V1Coursecontent__container1__img">
          <img src={Adults} alt="" />
        </div>
        <div className="V1Coursecontent__container1__title">
          <p>Adult course</p>
        </div>
        <div className="V1Coursecontent__container1__subtitle">
          <p>French A1 - A2</p>
        </div>
      </div>
      <div className="V1CourseContent__containersflex">
        <div className="V1Coursecontent__container2">
          <div className="V1Coursecontent__container2__title__special">
            <p>French A1 level</p>
          </div>
          <div className="V1Coursecontent__container2__paragraph">
            <p>
              Our French language lessons focus on enabling students to build
              their reading, writing, speaking, and listening skills. This
              course will provide you with the knowledge necessary to Comprehend
              and utilize common idioms and simple words targeted at meeting
              fundamental requirements. Introduce yourself and others; ask and
              answer personal questions such as where you live, who you know,
              and what you own. Engage in small talk if the other person speaks
              slowly and clearly and is willing to assist.
            </p>
          </div>

          <div className="V1Coursecontent__container2__title">
            <p>French A2 level</p>
          </div>
          <div className="V1Coursecontent__container2__paragraph">
            <p>
              A deeper look at the language and it’s origin. Our French language
              lessons focus on enabling students to build their reading,
              writing, speaking, and listening skills. This course will provide
              you with the knowledge necessary to grasp words and commonly used
              terms relevant to areas of immediate significance (e.g., very
              basic personal and family information, shopping, local geography,
              job). Communicate in accordance with basic and ordinary tasks that
              necessitate simple and straightforward exchanges of information on
              familiar and routine matters. Describe in simple words
              characteristics of your background, immediate environment, and
              urgent needs.
            </p>
          </div>
          <div className="V1Coursecontent__container2__title">
            <p>Admissions Requirements</p>
          </div>
          <div className="V1Coursecontent__container2__paragraph">
            <p>
              Please review admissions requirements before applying. This course
              is opened to everybody. Students should apply before the
              application deadline.
            </p>
          </div>
        </div>
        <div className="V1Coursecontent__container3">
          <div className="V1Coursecontent__container3__applicationdeadline">
            <div className="V1Coursecontent__container3__df">
              <div className="V1Coursecontent__container3__title">
                <p>APPLICATION DEADLINE</p>
              </div>

              <div className="V1Coursecontent__container3__applicationdeadline__line"></div>
            </div>

            <b>
              <p>Courses Offered</p>
            </b>
            <p>French A1</p>

            <div className="V1Coursecontent__Deadline">
              <p>Deadline: Feb 28, 2024 | 08:00 pm</p>
            </div>

            <div className="V1Coursecontent__arrowtxt">
              <div className="V1Coursecontent__arrow">
                <ArrowForward
                  style={{
                    fontSize: "20px",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                />
              </div>
              <div className="V1Coursecontent__txt">
                <b>
                  <p>Apply to A1 now</p>
                </b>
              </div>
            </div>
            <p>French A2</p>

            <div className="V1Coursecontent__Deadline">
              <p>Deadline: Feb 28, 2024 | 08:00 pm</p>
            </div>

            <div className="V1Coursecontent__arrowtxt">
              <div className="V1Coursecontent__arrow">
                <ArrowForward
                  style={{
                    fontSize: "20px",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                />
              </div>
              <div className="V1Coursecontent__txt">
                <b>
                  <p>Apply to A2 now</p>
                </b>
              </div>
            </div>


          </div>
          <div className="V1Coursecontent__container3__contact">
            <div className="V1Coursecontent__container3__df">
              <div className="V1Coursecontent__container3__contact__p">
                <p>CONTACT</p>
              </div>
              <div className="V1Coursecontent__container3__applicationdeadline__line2"></div>
            </div>
            <b>
              <p>Questions about the Program ?</p>
            </b>
            <div className="V1Coursecontent__arrowtxt">
              <div className="V1Coursecontent__arrow">
                <ArrowForward
                  style={{
                    fontSize: "20px",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                />
              </div>
              <div className="V1Coursecontent__txt">
                <b>
                  <p>Le Cercle Français Contact</p>
                </b>
              </div>
            </div>
          </div>
          <div className="V1Coursecontent__container3__teacher">
            <div className="V1Coursecontent__container3__teacher__line"></div>
            <div className="V1Coursecontent__container3__teacher__name">
              <b>
                <p> Bhavya Iyer</p>
              </b>
            </div>
            <div className="V1Coursecontent__container3__teacher__role">
              <p> Head teacher</p>
            </div>
            <div className="V1Coursecontent__container3__email">
              <b>
                <p>E-mail</p>
              </b>
            </div>
            <div className="V1Coursecontent__container3__emailaddress">
              <p> contact@lecerclefrancais.in</p>
            </div>
            <div className="V1Coursecontent__container3__phone">
              <b>
                <p>Phone </p>
              </b>
            </div>
            <div className="V1Coursecontent__container3__phonenumber">
              <p> 91 87880 97983</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AdultPage;
