import { Instagram } from "@mui/icons-material";
import React from "react";
import "../App.css";
import "./Footer.css";
import logoLCFR from "../img/logoLCFR.png";

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer__white">
        <div className="Footer__container">
          <div className="Footer__flex1">
            <a href="/">
              <div className="logoLCFR">
                <img src={logoLCFR} alt="Le Cercle Français Logo" />
              </div>
            </a>
          </div>
          <div className="Footer__flex2">
            <div className="Footer__title">
              <p>Navigate</p>
            </div>
            <div className="Footer__content">
              <a href="/">
                <p>Home</p>
              </a>
              <a href="/adultsprograms">
                <p>Adults programs</p>
              </a>

              <a href="/kidsprograms">
                <p>Kids programs</p>
              </a>
            </div>
          </div>
          <div className="Footer__flex3">
            <div className="Footer__title">
              <p>Legal</p>
            </div>
            <div className="Footer__content">
              <a href="/privacy">
                <p>Privacy Policy</p>
              </a>

              <a href="/cookies">
                <p>Cookie Policy</p>
              </a>
            </div>
          </div>
          <div className="Footer__flex4">
            <div className="Footer__title">
              <p>Contact us</p>
            </div>
            <div className="Footer__content">
              <p>91 87880 97983</p>
              <p>contact@lecerclefrancais.in</p>
              <a href="https://www.instagram.com/lecerclefrancais.pune/">
                <div className="Footer__white insta">
                  <Instagram style={{ fontSize: "32px", marginLeft: "-2px"}} />
                </div>
              </a>
            </div>
          </div>
          <div className="h32"></div>{" "}
        </div>

        <div className="Footer__grey">
          <p>© 2024 Le Cercle Français - All rights reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
