import React, { useState, useEffect } from "react";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Privacypolicy from "./Pages/Privacypolicy";
import Header from "./Sections/Header";

import CookiesPopup from "./component/CookiesPopup";
import { CookieConsentProvider } from "./CookieConsentContext";

import Cookiepolicy from "./Pages/Cookiepolicy";
import KidPage from "./Sections/KidPage";
import AdultPage from "./Sections/AdultPage";
import FreeCoursePage from "./Sections/FreeCoursePage";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="App">
      {loading ? (
          <div className="skeleton__container">
            <div className="h16"></div>
            <div className="Skeleton"></div>
            <div className="h16"></div>
            <div className="Skeleton2"></div>
          </div>
        ) : (
          <>
      <CookieConsentProvider>
        <Header />
        <CookiesPopup />
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/adultsprograms">
              <AdultPage />
            </Route>

            <Route exact path="/privacy">
              <Privacypolicy />
            </Route>
            <Route exact path="/cookies">
              <Cookiepolicy />
            </Route>
            <Route exact path="/kidsprograms">
              <KidPage />
            </Route>
            <Route exact path="/freecourse">
              <FreeCoursePage />
            </Route>
          </Switch>
        </Router>
      </CookieConsentProvider>
      </>  )}
    </div>
  );
}

export default App;
