import React, { useState } from "react";

import "./ContactZone.css";
import db from "../../firebase"; // Adjust the path as necessary
import { ref, push } from "firebase/database";
import "../../App.css"

import ReCAPTCHA from "react-google-recaptcha";

function ContactComponent() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
    setErrorMessage(""); // Clear error message when captcha is completed
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !email ||
      !phone ||
      !selectedQuestion ||
      !captchaValue ||
      !consent1 ||
      !consent2
    ) {
      // Check for missing information
      setErrorMessage(
        "*Missing information, please fill the form and agree to the terms"
      );
      return;
    }

    // Reset the error message if all fields are filled
    setErrorMessage("");

    // Use the 'db' instance for Firebase Database
    const contactRef = ref(db, "contacts");

    // Additional data to save
    const additionalData = {
      surname: surname,
      message: message,
    };

    push(contactRef, {
      name: name,
      email: email,
      phone: phone,
      selectedQuestion: selectedQuestion,
      ...additionalData,
    })
      .then(() => {
        console.log("Data saved successfully.");
        setName("");
        setEmail("");
        setPhone("");
        setSurname("");
        setMessage("");
        setSelectedQuestion("");
        setIsSubmitted(true);
        setTimeout(() => setIsSubmitted(false), 5000);
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
      });
  };

  return (
    <div className="V1Contact__inputs">
      <div className="V1Contact__question">
        <div className="V1Contact__questionlabel">
          <p>Your question is about</p>
        </div>
        <div className="V1Contact__textinput hover">
          <select
            value={selectedQuestion}
            onChange={(e) => setSelectedQuestion(e.target.value)}
            className="styled-dropdown"
          >
            <option value="" disabled>
              Select a question
            </option>
            <option value="program">Programs</option>
            <option value="enrollment">Enrollment</option>
          </select>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="V1Contact__text">
          <div className="V1Contact__textlabel">
            <p>Name</p>
          </div>
          <div className="V1Contact__textinput">
            <input type="text" value={name} onChange={handleNameChange} />
          </div>
        </div>

        <div className="V1Contact__text">
          <div className="V1Contact__textlabel">
            <p>Surname</p>
          </div>
          <div className="V1Contact__textinput">
            <input
              type="text"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
        </div>

        <div className="V1Contact__text">
          <div className="V1Contact__textlabel">
            <p>E-mail</p>
          </div>
          <div className="V1Contact__textinput">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="V1Contact__text">
          <div className="V1Contact__textlabel">
            <p>Phone</p>
          </div>
          <div className="V1Contact__textinput">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="V1Contact__text">
          <div className="V1Contact__textlabel">
            <p>Message</p>
          </div>
          <div className="V1Contact__textinput V1Contact__height">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={5} // Adjust the number of rows as needed
            />
          </div>
        </div>

        <div className="V1Contact__consent">
          <div className="V1Contact__container">
            <div className="V1Contact__icon">
              <input
                type="checkbox"
                id="consent1"
                checked={consent1}
                onChange={() => setConsent1(!consent1)}
              />
            </div>
            <div className="V1Contact__text">
              <p>
                By continuing you agree to Le Cercle Français{" "}
                <span className="V1Contact__legal">Terms & Conditions</span> and{" "}
                <span className="V1Contact__legal">Privacy Policy</span>
              </p>
            </div>
          </div>
          <div className="V1Contact__container">
            <div className="V1Contact__icon">
              <input
                type="checkbox"
                id="consent2"
                checked={consent2}
                onChange={() => setConsent2(!consent2)}
              />
            </div>
            <div className="V1Contact__text">
              <p>
                I want to receive information later about the institute
                regarding my question
              </p>
            </div>
          </div>
          <div className="Captcha">
            <ReCAPTCHA
              sitekey="6Lc0AkUpAAAAACvpTUucFpGovUxv-_FJ-qkmXJ2T"
              onChange={onCaptchaChange}
            />
          </div>
          <div className="Aligncenter">
            <button type="submit" className="V1CTA">
              Send
            </button>
          </div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}{" "}
        </div>
      </form>
      {isSubmitted && <p className="success-message">Message sent</p>}
      <div className="space"></div>
    </div>
  );
}

export default ContactComponent;
