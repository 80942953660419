import React from "react";
import "./Home.css";
import Footer from "../Sections/Footer";

import LandingPage from "../Sections/HomeComponents/LandingPage";
import FreeCourse from "../Sections/HomeComponents/FreeCourse";
import Offers from "../Sections/HomeComponents/Offers";
import ContactZone from "../Sections/HomeComponents/ContactZone";

function Home() {
 
  return (
    <div className="Home">
      <div className="Home__content">
     
            <LandingPage />
            <FreeCourse />
            <Offers />
            <ContactZone />
            <Footer />
       
      </div>
    </div>
  );
}

export default Home;
