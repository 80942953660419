import React from "react";
import Contact from "../../img/Contact.png";
import "./ContactZone.css";

import ContactComponent from "./ContactComponent";

function ContactZone() {
  return (
    <div className="V1Contact__div">
      <div className="V1Contact__img">
        <img src={Contact} alt="Le Cercle Français Background" />
      </div>

      <div className="V1Contact__items">
        <p>Request for information</p>
      </div>

      <div className="V1Contact__form">
        <div className="V1Contact__paragraph">
          <p>
            Le Cercle Français is a French Language Institute that teaches
            French to individuals above the age of six. Whether you're looking
            for a tutor, wish to study French language informally, or learn for
            professional reasons, we will customise the classes according to
            your needs. We provide both individual and group lessons.
          </p>
        </div>
        <div className="V1Contact__datacontact">
          <p>
            We are at your entire disposal to answer your questions on our
            courses and inscriptions
          </p>
          <p>
            E-mail :{" "}
            <span className="spanned">contact@lecerclefrancais.in</span>
          </p>
          <p>By phone : 91 87880 97983</p>
        </div>
        <ContactComponent />
      </div>
    </div>
  );
}

export default ContactZone;
