import React, { useState } from "react";
import Kids from "../../img/Kids.png";
import Adults from "../../img/Adults.png";
import "./Offers.css";

function Offers() {
  return (
    <div className="div">
      <div className="V1KidProgram__Heading">
        <p>Our French language courses</p>
      </div>
      <div className="V1KidProgram__line"></div>

      <a href="/kidsprograms">
        <div className="OnlineKids">
          <div className="OnlineKids__img">
            <img src={Kids} alt="Le Cercle Français" />
          </div>

          <div className="OnlineKids__rightcontent">
            <div className="OnlineKids__headings">
              <p>French courses for kids</p>
            </div>

            <div className="OnlineKids__content">
              <p>
                Designed specifically for children of ages 6-12 and separated
                into three levels from the Common European Framework of
                Reference for Languages (CEFR). Introduce your kid to French
                with our courses made just for young learners.
              </p>
            </div>
          </div>
        </div>
      </a>

      <a href="/adultsprograms">
        <div className="OnlineAdults">
          <div className="OnlineAdults__img">
            <div className="OnlineAdults__mobile">
              <img src={Adults} alt="Le Cercle Français" />
            </div>

            <div className="OnlineAdults__headings">
              <p>French courses for adults</p>
            </div>

            <div className="OnlineAdults__course">
              <p>
                French language classes that are thoughtfully designed to meet
                your needs. Whether you're learning for enjoyment, for school,
                or for business, we take pride in our ability to accommodate you
                and your preferred style of learning.
              </p>
            </div>
          </div>

          <div className="OnlineAdults__desktop">
            <img src={Adults} alt="Le Cercle Français" />
          </div>
        </div>
      </a>
    </div>
  );
}

export default Offers;
