import React from "react";
import "./FreeCourse.css";
import { ArrowForward } from "@mui/icons-material";

function FreeCourse() {
  return (
    <div className="V1CourseFree">
      <a href="/freecourse" style={{ color: "white" }}>
        <div className="V1CourseFree__line"></div>
        <div className="V1CourseFree__FristLine">
          <div className="V1CourseFree__Title">
            <p>Book a free trial French class</p>
          </div>

          <div className="V1CourseFree__mentionright">
            <ArrowForward style={{ fontSize: "40px", marginTop: "20px" }} />
          </div>
        </div>

        <div className="V1CourseFree__SecondLine">
          <div className="V1CourseFree__desc">
            <p>Open to everyone</p>
          </div>
          <div className="V1CourseFree__Freemention">
            <p>Online course</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default FreeCourse;
